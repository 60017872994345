import {PERP_API_URL, TWITTER_CLIENT_ID} from '@/configs';
import {ROUTE_PATH} from '@/constants/route-path';
import {AssetsContext} from '@/contexts/assets-context';
import {WalletContext} from '@/contexts/wallet-context';
import {getIsFirstTimeUser, setIsFirstTimeUser} from '@/utils/alpha.storage';
import {isWebApp} from '@/utils/client';
import useAnalyticsEventTracker, {AlphaActions} from '@/utils/ga';
import {getUuid} from '@/utils/helpers';
import {useContext, useEffect, useRef, useState} from 'react';
import InstallPwaModal from '../InstallPwaModal';
import s from './TwitterSignIn.module.scss';
import SignInFormContainer from "@/modules/AlphaPWA/Welcome/SignInForm";
import LogInWebOnAndroid from "@/modules/AlphaPWA/Welcome/LogInWebOnAndroid";
import {generateToken, setBearerToken} from "@/services/player-share";
import localStorage from "@/utils/localstorage";
import {TWITTER_TOKEN} from "@/constants/storage-key";
import {setBearerToken as setBearerTokenTweet} from "@/services/alpha_tweet";
import {useRouter} from "next/router";
import CreateOrImportWallet from "@/modules/AlphaPWA/Welcome/CreateOrImportWallet";

const TwitterSignIn = () => {
  const { addressL2 } = useContext(WalletContext);
  const { playerPoolProfile } = useContext(AssetsContext);
  const uuid = getUuid();
  const isPWA = isWebApp();
  const gaEventTracker = useAnalyticsEventTracker();
  const timer = useRef<any>();
  const router = useRouter();
  const { token = '' } = router.query;
  const [hasLinkTwitter, setHasLinkTwitter] = useState<boolean>(false);
  const [isClickLinkTwitter, setIsClickLinkTwitter] = useState<boolean>(false);
  const [showAuthorizeMethod, setShowAuthorizeMethod] = useState(false);
  const [showTrouble, setShowTrouble] = useState(false);

  useEffect(() => {
    if (!getIsFirstTimeUser()) {
      gaEventTracker(AlphaActions.InstallNBCApp, addressL2 || '');
      setIsFirstTimeUser();
    }
  }, []);

  const URL = `${window.location.origin}${ROUTE_PATH.ALPHA_MOBILE_INITIAL}`;

  const getTwitterOauthUrl = () => {
    const rootUrl = 'https://twitter.com/i/oauth2/authorize';
    const options = {
      redirect_uri: `${PERP_API_URL}/twitter-api/oauth/twitter?callbackURL=${URL}&uuid=${uuid}`,
      client_id: TWITTER_CLIENT_ID,
      state: 'state',
      response_type: 'code',
      code_challenge: 'challenge',
      code_challenge_method: 'plain',
      scope: [
        'users.read',
        'tweet.read',
        'follows.read',
        'offline.access',
      ].join(' '),
    };
    const qs = new URLSearchParams(options).toString();
    return `${rootUrl}?${qs}`;
  };

  const handleVerifyTwitter = async (): Promise<void> => {
    try {
      const result = await generateToken(uuid);
      if (result) {
        clearInterval(timer.current);
        const twitterToken = localStorage.get(TWITTER_TOKEN);
        if (!twitterToken || twitterToken !== result?.token) {
          localStorage.set(TWITTER_TOKEN, result?.token);
          setBearerToken(result?.token);
          setBearerTokenTweet(result?.token);
        }
        setHasLinkTwitter(true);
      }
    } catch (e) {
      console.log('handleVerifyTwitter TwitterSignIn', e);
    }
  };

  useEffect(() => {
    if (
      (isClickLinkTwitter && !addressL2) ||
      (isClickLinkTwitter && !playerPoolProfile?.twitterId)
    ) {
      timer.current = setInterval(async () => {
        handleVerifyTwitter();
      }, 2000);
    }
    return () => {
      clearInterval(timer.current);
    };
  }, [isClickLinkTwitter, addressL2, playerPoolProfile]);

  useEffect(() => {
    if (token) {
      handleVerifyTwitter();
    }
  }, [token]);

  return (
    <>
      {isPWA ? (
        <div className={s.wrapper}>
          {!hasLinkTwitter && !playerPoolProfile?.twitterId ? (
            <div>
              <SignInFormContainer setShowTrouble={setShowTrouble}/>
              {
                showTrouble && (
                  <>
                    {
                      showAuthorizeMethod ? (
                        <div>
                          <LogInWebOnAndroid link={getTwitterOauthUrl()} setIsClickLinkTwitter={setIsClickLinkTwitter} setShowAuthorizeMethod={setShowAuthorizeMethod}/>
                        </div>
                      ) : (
                        <div className={s.troubleText} onClick={() => setShowAuthorizeMethod(pre => !pre)}>Have trouble...?</div>
                      )
                    }
                  </>
                )
              }
            </div>
          ) : null}
          {hasLinkTwitter ? <CreateOrImportWallet showTitle={true}/> : null}
        </div>
      ) : (
        <InstallPwaModal />
      )}
    </>
  );
};

export default TwitterSignIn;
