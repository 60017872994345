import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import toast from 'react-hot-toast';
import cs from 'classnames';
import { Wallet, ethers } from 'ethers';
import { useRouter } from 'next/router';
import { Spinner } from 'react-bootstrap';

import { ROUTE_PATH } from '@/constants/route-path';
import { WalletContext } from '@/contexts/wallet-context';
import { ClientOs } from '@/enums/client';
import {
  createAddressLinkTwitter,
  getLastAddressLinkTwitter,
} from '@/services/player-share';
import { getErrorMessage } from '@/utils/error';
import { detectClientOs } from '@/utils/client';
import { AssetsContext } from '@/contexts/assets-context';
import { IGetPlayerPoolProfile } from '@/interfaces/api/player-share';
import isEmpty from 'lodash/isEmpty';
import errorLogger from '@/services/errorLogger';

import s from './CreateOrImportWallet.module.scss';
import useAnalyticsEventTracker, { AlphaActions } from '@/utils/ga';
import {requestReload} from "@/state/common";
import {useDispatch} from "react-redux";
import useTwitterSignInModal from "@/modules/AlphaPWA/Welcome/useTwitterSignInModal";
import {ONBOARDING_TWITTER_PROFILE} from "@/constants/storage-key";

const CreateOrImportWallet: React.FC<any> = ({onClose, showTitle}: any): React.ReactElement => {
  const router = useRouter();
  const clientOs = detectClientOs();
  const [isProcess, setIsProcess] = useState<boolean>(false);
  const [lastAddressLinkTwitter, setLastAddressLinkTwitter] = useState<
    IGetPlayerPoolProfile | undefined | null
  >(undefined);
  const [isOldWalletAddress, setIsOldWalletAddress] = useState<boolean>(false);

  const gaEventTracker = useAnalyticsEventTracker();
  const dispatch = useDispatch();

  const { addressL2, keySetL2, onRandomAccountL2 } = useContext(WalletContext);
  const { getPlayerPoolProfileByAddress, getReferralCodeFromContext } =
    useContext(AssetsContext);
  const {closeSignInModal} = useTwitterSignInModal();

  const linkAddressWithTwitter = async (): Promise<void> => {
    if (addressL2 && keySetL2?.prvKey) {
      try {
        const timeStamp = new Date().getTime();
        const wallet = new Wallet(keySetL2.prvKey);
        const signMessage = ethers.utils.toUtf8Bytes(`${timeStamp}`);
        const signature = await wallet.signMessage(signMessage);
        const result = await createAddressLinkTwitter({
          address: addressL2,
          timestamp: timeStamp,
          signature,
        });
        if (!result || result?.code < 0 || typeof result === 'string') {
          toast.error(result?.message || 'Twitter authorize failed.');
          errorLogger.report({
            address: addressL2,
            action: AlphaActions.CreateNewWallet,
            error: JSON.stringify({
              error: 'Failed to create address link twitter',
              info: result,
              isOldWalletAddress,
            }),
          });
        } else {
          await getPlayerPoolProfileByAddress();
          getReferralCodeFromContext();
          dispatch(requestReload());

          const twitterProfile = JSON.parse(localStorage.getItem(ONBOARDING_TWITTER_PROFILE) as string);
          if (twitterProfile && !twitterProfile?.issued) {
            router.push(`${ROUTE_PATH.ALPHA_MOBILE_KEYS}?token_tab=2`);
          } else {
            router.push(`${ROUTE_PATH.ALPHA_MOBILE_DM}?show=daos,tribes`);
          }

          onClose && onClose();
          closeSignInModal();
        }
        // tracking
        gaEventTracker(
          AlphaActions.CreateNewWallet,
          JSON.stringify({
            address: addressL2 || '',
            is_old_wallet: isOldWalletAddress,
          })
        );
      } catch (error) {
        const { message } = getErrorMessage(error);
        toast.error(message);
        errorLogger.report({
          address: addressL2,
          action: AlphaActions.CreateNewWallet,
          error: JSON.stringify({
            error: 'Failed to create address link twitter via client',
            info: message,
            isOldWalletAddress,
          }),
        });
      }
    }
    setIsProcess(false);
  };

  const createWallet = async (isOldWallet: boolean) => {
    setIsProcess(true);
    setIsOldWalletAddress(isOldWallet);
    // have a wallet before ?
    if (addressL2 && keySetL2?.prvKey) {
      linkAddressWithTwitter();
    } else {
      // create a new wallet + private key
      const password = `${Math.floor(1000 + Math.random() * 9000)}`;
      await onRandomAccountL2({ password });
    }
  };

  const importPrivateKey = () => {
    onClose && onClose();
    closeSignInModal();
    router.push(ROUTE_PATH.ALPHA_MOBILE_IMPORT_KEY);
  };

  useEffect(() => {
    if (lastAddressLinkTwitter === undefined) {
      (async () => {
        const result = await getLastAddressLinkTwitter();
        if (isEmpty(result) === false) {
          setLastAddressLinkTwitter(result);
        } else {
          setLastAddressLinkTwitter(null);
        }
      })();
    }
  }, []);

  useEffect(() => {
    if (
      lastAddressLinkTwitter !== undefined &&
      lastAddressLinkTwitter === null
    ) {
      createWallet(false);
    }
  }, [lastAddressLinkTwitter]);

  useEffect(() => {
    if (addressL2 && isProcess) {
      linkAddressWithTwitter();
    }
  }, [addressL2]);

  return (
    <div
      className={cs(s.createOrImportWallet, clientOs === ClientOs.Ios && s.ios)}
    >
      <div>
        <div
          className={cs({
            'd-none':
              lastAddressLinkTwitter === undefined ||
              lastAddressLinkTwitter === null,
          })}
        >
          {showTitle && <h6 className={s.title}>You already have a wallet</h6>}
          <div className={s.content}>
            {/* <div className={s.caption}>To get started with Alpha</div> */}
            <div className={s.note}>
              <div>
                A wallet had been linked to this X account, please import the private key to continue using the same wallet with Alpha.
              </div>
              <div>Alternatively, you can create a new wallet.</div>
            </div>
          </div>
          <div className={s.actionBlock}>
            <Button
              className={s.btn}
              onClick={importPrivateKey}
              disabled={isProcess}
            >
              <svg width="17" height="16" viewBox="0 0 17 16" fill="none">
                <path
                  d="M4.0413 7.13932C3.6073 6.72932 3.89725 5.99933 4.49459 6H6.10933V2.66667C6.10933 2.29867 6.408 2 6.776 2H10.776C11.144 2 11.4427 2.29867 11.4427 2.66667V6H13.172C13.7693 6 14.0593 6.72932 13.6253 7.13932L9.44202 11.0913C9.10068 11.414 8.56657 11.414 8.22457 11.0913L4.0413 7.13932ZM13.5 13.5H4.16663C3.89063 13.5 3.66663 13.724 3.66663 14C3.66663 14.276 3.89063 14.5 4.16663 14.5H13.5C13.776 14.5 14 14.276 14 14C14 13.724 13.776 13.5 13.5 13.5Z"
                  fill="white"
                />
              </svg>
              Import private key
            </Button>
            <Button
              className={cs(s.btn, s.lowPriorityBtn)}
              onClick={() => createWallet(true)}
              disabled={isProcess}
            >
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path
                  d="M6.41341 3.66667C4.69341 3.66667 3.66667 4.69333 3.66667 6.41333V11.1167C3.66667 11.2333 3.56327 11.3313 3.44727 11.32C2.48593 11.224 2 10.6427 2 9.58667V3.74666C2 2.58666 2.58674 2 3.74674 2H9.58659C10.6426 2 11.224 2.48601 11.32 3.44735C11.332 3.56335 11.234 3.66667 11.1174 3.66667H6.41341ZM14 6.41667V12.25C14 13.4167 13.4167 14 12.25 14H6.41667C5.25 14 4.66667 13.4167 4.66667 12.25V11.3333V6.41667C4.66667 5.25 5.25 4.66667 6.41667 4.66667H11.3333H12.25C13.4167 4.66667 14 5.25 14 6.41667ZM11.5 9.33333C11.5 9.05733 11.276 8.83333 11 8.83333H9.83333V7.66667C9.83333 7.39067 9.60933 7.16667 9.33333 7.16667C9.05733 7.16667 8.83333 7.39067 8.83333 7.66667V8.83333H7.66667C7.39067 8.83333 7.16667 9.05733 7.16667 9.33333C7.16667 9.60933 7.39067 9.83333 7.66667 9.83333H8.83333V11C8.83333 11.276 9.05733 11.5 9.33333 11.5C9.60933 11.5 9.83333 11.276 9.83333 11V9.83333H11C11.276 9.83333 11.5 9.60933 11.5 9.33333Z"
                  fill="white"
                />
              </svg>
              Create new wallet
            </Button>
          </div>
        </div>
        {isProcess && (
          <div className={s.loadingWrapper}>
            <Spinner variant="info" />
            <div className={s.warningText}>
              Please do not close the app while the process is running.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateOrImportWallet;
