/* eslint-disable @typescript-eslint/no-unused-vars */

import React, {useEffect, useRef, useState} from 'react';
import {Box, Flex, Text} from '@chakra-ui/react';
import styles from './styles.module.scss';
import {Form} from 'react-final-form';
import IssueForm from './form';
import {generateTokenWithTwPost, setBearerToken} from "@/services/player-share";
import localStore from "@/utils/localstorage";
import {ONBOARDING_TWITTER_PROFILE, TWITTER_TOKEN} from "@/constants/storage-key";
import {setBearerToken as setBearerTokenTweet} from "@/services/alpha_tweet";
import useAnalyticsEventTracker, {AlphaActions} from "@/utils/ga";
import {getReferralCode, getReferralCodeByTwId} from "@/services/referral";
import {getLink} from "@/utils/helpers";
import TwitterIcon from "@/modules/AlphaPWA/Welcome/TwitterSignIn/TwitterIcon";

const SignInFormContainer = ({onClose, setShowTrouble}: any) => {
  const refForm = useRef<any>();
  const [submitting, setSubmitting] = useState(false);

  const timer = useRef<any>();
  const [hasLinkTwitter, setHasLinkTwitter] = useState<boolean>(false);
  const [twProfile, setTwProfile] = useState<any>(null);
  const gaEventTracker = useAnalyticsEventTracker();

  useEffect(() => {
    if (twProfile) {
      setSubmitting(true);
      timer.current = setInterval(async () => {
        handleVerifyTwitter();
      }, 5000);
    }
    return () => {
      clearInterval(timer.current);
    };
  }, [twProfile]);

  const handleVerifyTwitter = async (): Promise<void> => {
    try {
      const result = await generateTokenWithTwPost(twProfile?.secret_code);
      if (result) {
        clearInterval(timer.current);
        const twitterToken = localStore.get(TWITTER_TOKEN);
        if (!twitterToken || twitterToken !== result?.token) {
          localStore.set(TWITTER_TOKEN, result?.token);
          setBearerToken(result?.token);
          setBearerTokenTweet(result?.token);
        }
        setHasLinkTwitter(true);
        setSubmitting(false);
        setShowTrouble && setShowTrouble(false);

        if(twProfile?.issued) {
          gaEventTracker(AlphaActions.PostTweetSignInSuccessTw, JSON.stringify({
            info: {
              twitter_username: twProfile?.twitter_username,
            },})
          );
        } else {
          gaEventTracker(AlphaActions.PostTweetSignUpSuccessTw, JSON.stringify({
            info: {
              twitter_username: twProfile?.twitter_username,
            },})
          );
        }

        try {
          getReferralCode();
        } catch (e) {
          console.log('getReferralCode', e);
        }
      }
    } catch (err) {
      console.log('handleVerifyTwitter', err);
    }
  };

  const onSubmit = async (values: any) => {
    const { referralCode, twitterProfile } = values;
    try {
      setTwProfile(twitterProfile);
      localStorage.setItem(ONBOARDING_TWITTER_PROFILE, JSON.stringify(twitterProfile));

      const shareUrl = getLink(referralCode);
      let content = '';

      if(twitterProfile?.issued) {
        content = `I’ve joined @NewBitcoinCity, and I’m having a blast!\n\nThe features are top-notch and interface is simply intuitive. Plus, there are countless cool tools to build your community.\n\nI’m happy to hook you up right here:\n\n#alpha${twitterProfile?.public_code}\n\n`;
      } else {
        content = `I've joined @NewBitcoinCity, and I'm getting a kick out of it!\n\nChat. Trade. Play. And other prompts are making the app easy and exciting to use.\n\nI'm happy to hook you up here:\n\n#alpha${twitterProfile?.public_code}\n\n`;
      }

      window.open(
        `https://twitter.com/intent/tweet?url=${shareUrl}&text=${encodeURIComponent(
          content
        )}`,
        '_blank'
      );

      gaEventTracker(AlphaActions.PostTweetVerifyTw, JSON.stringify({
        info: {
          twitter_username: twitterProfile?.twitter_username,
        },})
      );

      setTimeout(() => {
        setSubmitting(false);
      }, 60000 * 2);
    } catch (e) {
      // showError({
      //   ...getErrorMessageContract(e),
      // });
      console.log('IssueFormContainer', e);
    } finally {
      // setSubmitting(false);
    }
  };

  return (
    <Flex
      className={styles.formContainer}
      direction={'column'}
      alignItems={'center'}
      gap={3}
    >
      <Text className={styles.formTitle}>
        {
          hasLinkTwitter ? 'Verified successfully!' : (
            <Flex alignItems={"center"} gap={2}>
              What is your
              <TwitterIcon />
              username
            </Flex>
          )
        }
      </Text>
      <Box className={styles.formWrapper} mt={7}>
        <Form
          onSubmit={onSubmit}
          initialValues={{}}
        >
          {({ handleSubmit }: any) => (
            <IssueForm
              handleSubmit={handleSubmit}
              ref={refForm}
              submitting={submitting}
              hasLinkTwitter={hasLinkTwitter}
              onClose={onClose}
              setShowTrouble={setShowTrouble}
            />
          )}
        </Form>
      </Box>
    </Flex>
  );
};

export default SignInFormContainer;
