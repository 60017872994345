import {closeModal, openModal} from "@/state/modal";
import s from "./styles.module.scss";
import React from "react";
import {useDispatch} from "react-redux";
import useWindowSize from "@/hooks/useWindowSize";
import TwitterSignIn from "@/modules/AlphaPWA/Welcome/TwitterSignIn";

const WELCOME_MODAL_NAME = 'WELCOME_MODAL_NAME';

const useTwitterSignInModal = () => {
  const dispatch = useDispatch();
  const { mobileScreen } = useWindowSize();

  const openSignInModal = () => {
    dispatch(
      openModal({
        id: WELCOME_MODAL_NAME,
        theme: 'dark',
        className: s.modalContent,
        modalProps: {
          centered: true,
          zIndex: 9999999,
          // size: mobileScreen ? 'full' : 'xxl',
        },
        hideCloseButton: false,
        render: () => (
          <TwitterSignIn
            // hasLinkTwitters={false}
            // setIsClickLinkTwitter={setIsClickLinkTwitter}
          />
        ),
      })
    );
  }

  const closeSignInModal = () => {
    dispatch(closeModal({id: WELCOME_MODAL_NAME}));
  }

  return {
    openSignInModal,
    closeSignInModal
  }
};

export default useTwitterSignInModal;
