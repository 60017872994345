/* eslint-disable react/display-name */
/* eslint-disable react/no-children-prop */

import React, {forwardRef, useCallback, useState,} from 'react';
import cx from 'clsx';
import styles from './styles.module.scss';
import s from './styles.module.scss';
import {Field, useForm, useFormState} from 'react-final-form';
import {Box, Button, Flex, Spinner, Text} from '@chakra-ui/react';
import InputWrapper from '@/modules/AlphaPWA/Profiles/TradeKey/components/form/inputWrapper';
import FieldText from '@/modules/AlphaPWA/Profiles/TradeKey/components/form/fieldText';
import {composeValidators, required} from '@/utils/form-validate';
import debounce from 'lodash/debounce';
import AvatarTwitter from '@/components/AvatarTwitter';
import {formatName,} from '@/utils';
import {ITwitterProfile} from '@/interfaces/presale';
import {requestAuthenByUserName} from "@/services/player-share";
import CreateOrImportWallet from "@/modules/AlphaPWA/Welcome/CreateOrImportWallet";
import useAnalyticsEventTracker, {AlphaActions} from "@/utils/ga";
import {getReferralCodeByTwId} from "@/services/referral";
import TwitterIcon from "@/modules/AlphaPWA/Welcome/TwitterSignIn/TwitterIcon";

const IssueForm: React.FC<any> = forwardRef(
  ({ handleSubmit, submitting, hasLinkTwitter, onClose, setShowTrouble }: any, ref: any) => {
    const [loading, setLoading] = useState(false);

    const [twitterProfile, setTwitterProfile] =
      useState<ITwitterProfile | null>(null);
    const { change } = useForm();
    const { values } = useFormState();
    const [hasInput, setHasInput] = useState(false);

    const gaEventTracker = useAnalyticsEventTracker();

    const onChangeTwitterUserName = (username: any) => {
      setHasInput(true);
      onTwitterUserNameChange({
        username: username,
      });
    };

    const onTwitterUserNameChange = useCallback(
      debounce(p => handleBaseAmountChange(p), 2000),
      []
    );

    const handleBaseAmountChange = async ({
      username,
    }: {
      username: string;
    }) => {
      try {
        setLoading(true);
        setTwitterProfile(null);
        setShowTrouble(false);
        change('twitterProfile', null);
        const res: any = await requestAuthenByUserName(username);

        setTwitterProfile(res);
        change('twitterProfile', res);

        if(!!res) {
          setShowTrouble(true);
        }

        const referralCode = await getReferralCodeByTwId({
          twitter_id: res?.twitter_id,
        });

        change('referralCode', referralCode);

        gaEventTracker(AlphaActions.EnterUsernameVerifyTw, JSON.stringify({
          info: {
            twitter_username: res?.twitter_username,
          },})
        );
      } catch (err: any) {
        console.log('handleBaseAmountChange err', err);
      } finally {
        setLoading(false);
        setHasInput(false);
      }
    };

    const btnDisable =
      submitting ||
      loading ||
      !twitterProfile;

    return (
      <form onSubmit={handleSubmit} ref={ref}>
        <InputWrapper
          // label={"Enter your X username below."}
          className={cx(styles.inputAmountWrap)}
          theme="light"
        >
          <Field
            name="username"
            children={FieldText}
            validate={composeValidators(required)}
            fieldChanged={onChangeTwitterUserName}
            disabled={submitting || loading || hasLinkTwitter}
            placeholder={`X username`}
            className={cx(styles.inputAmount)}
            borderColor={'#ECECED'}
            maxlength={50}
          />
        </InputWrapper>
        {loading ? (
          <Flex alignItems={'center'} justifyContent={'center'} mt={'12px'}>
            <Spinner size={'xl'} color="blue.500"></Spinner>
          </Flex>
        ) : (
          <>
            {twitterProfile && (
              <Box mt={6}>
                <Flex
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  px={4}
                  py={3}
                  bgColor={'#FAFAFA'}
                  borderRadius={'8px'}
                  className={s.wrapTwitter}
                >
                  <Flex gap={2} alignItems={'center'}>
                    <AvatarTwitter
                      url={twitterProfile?.twitter_avatar || ''}
                      address={twitterProfile?.twitter_username || ''}
                      width={40}
                    />
                    <Flex direction={'column'} color={'#000000'} gap={2}>
                      <Flex gap={1} alignItems={'center'}>
                        <Text
                          fontSize={'14px'}
                          lineHeight={'18px'}
                          fontWeight={500}
                        >
                          {formatName(twitterProfile?.twitter_name || '', 40)}
                        </Text>
                      </Flex>
                      <Text
                        color={'#000000'}
                        fontSize={'10px'}
                        opacity={'70%'}
                        fontWeight={400}
                      >
                        @{twitterProfile?.twitter_username}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>

                {twitterProfile?.issued ? (
                  <Flex alignItems={'center'} gap={8}>
                    <Text
                      fontSize={'12px'}
                      fontWeight={400}
                      color={"#FFFFFF"}
                      opacity={"70%"}
                      mt={4}
                    >
                      You already had an Alpha account.
                    </Text>
                  </Flex>
                ) : (
                  <></>
                )}
              </Box>
            )}
            {!!values.username && !twitterProfile && !hasInput && (
              <Text
                fontSize={'12px'}
                fontWeight={400}
                color={"#FF4747"}
                mt={4}
              >
                This account doesn’t exist on X.
              </Text>
            )}
          </>
        )}
        {hasLinkTwitter ? <CreateOrImportWallet onClose={onClose}/> : (
          <>
            {
              !!twitterProfile && (
                <Flex direction={'column'} mt={8}>
                  <Button
                    type="submit"
                    isLoading={submitting}
                    isDisabled={btnDisable}
                    loadingText="Processing"
                    className={styles.btnSubmit}
                    // onClick={() => {
                    //   const shareUrl = getLink(values?.referralCode);
                    //   let content = `I’ve joined @NewBitcoinCity, and I’m having a great time!\n\nReceived 1,010 $LOUNGE tokens for free to have some fun.\n\nI'm happy to hook you up here!\n\n#alpha${twitterProfile?.public_code}\n\n`;
                    //   window.open(
                    //     `https://twitter.com/intent/tweet?url=${shareUrl}&text=${encodeURIComponent(
                    //       content
                    //     )}`,
                    //     '_blank'
                    //   );
                    //
                    //
                    //   gaEventTracker(AlphaActions.PostTweetVerifyTw, JSON.stringify({
                    //     info: {
                    //       twitter_username: twitterProfile?.twitter_username,
                    //     },})
                    //   );
                    // }}
                  >
                    <Flex alignItems={"center"} gap={1}>
                      Post on
                      <TwitterIcon />
                      to sign in
                    </Flex>
                  </Button>
                </Flex>
              )
            }
          </>
        )}

      </form>
    );
  }
);

export default IssueForm;
