import React from 'react';
import s from './styles.module.scss';
import { CDN_URL_ICONS } from '@/configs';
import { detectClientOs } from '@/utils/client';
import { ClientOs } from '@/enums/client';
import { Box } from '@chakra-ui/react';

const InstallPwaModal: React.FC = (): React.ReactElement => {
  const clientOs = detectClientOs();

  return (
    <div style={{
      height: `${window.innerHeight}px`
    }} className={s.wrapper}>
      <Box className={s.modalBody}>
        <div className={s.thumbnailWrapper}>
          <img
            className={s.logo}
            src={`${CDN_URL_ICONS}/icon-download-app.svg`}
            alt="logo"
          />
        </div>
        <h2 className={s.title}>Add to Home Screen</h2>
        <p className={s.subTitle}>
          One minute installation.
          <br />
          No wallet or deposit is required. BTC and ETH are accepted. Simply
          sign up and immediately claim your earnings.
        </p>
        <ul className={s.stepList}>
          <li className={s.stepItem}>
            1. Tap on{' '}
            <img
              className={s.shareIosImg}
              src={`${CDN_URL_ICONS}/share-${
                clientOs === ClientOs.Ios ? 'ios' : 'android'
              }.png`}
              alt="share-ios"
            ></img>
          </li>
          <li className={s.stepItem}>
            2. Select{' '}
            <img
              className={s.addHomeIosImg}
              src={`${CDN_URL_ICONS}/${
                clientOs === ClientOs.Ios ? 'screenios.png' : 'android1.png'
              }`}
              alt="add-to-screen"
            ></img>
          </li>
        </ul>
        <p className={s.subTextBottom}>
          Alpha uses progressive web app (PWA) technology that combines the best
          features of traditional websites and platform-specific apps.
        </p>
      </Box>
    </div>
  );
};

export default React.memo(InstallPwaModal);
