import React from 'react';
import Image from 'next/image';
import { CDN_URL_ICONS, CDN_URL_IMAGES } from '@/configs';
const TwitterIcon = (props: any) => {
  return (
    <Image
      src={`${CDN_URL_ICONS}/TwitterIcon.png`}
      alt={'TwitterIcon'}
      width={32}
      height={32}
    />
  );
};

export default TwitterIcon;
