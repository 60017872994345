const CopyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
    >
      <path
        d="M10 4.5H5C4.44772 4.5 4 4.94772 4 5.5V10.5C4 11.0523 4.44772 11.5 5 11.5H10C10.5523 11.5 11 11.0523 11 10.5V5.5C11 4.94772 10.5523 4.5 10 4.5Z"
        stroke="#F8F0AC"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 8.5C1.45 8.5 1 8.05 1 7.5V2.5C1 1.95 1.45 1.5 2 1.5H7C7.55 1.5 8 1.95 8 2.5"
        stroke="#F8F0AC"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CopyIcon;
